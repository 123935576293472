import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';

const tenantImprovementDetailOptions = [
  { display_name: 'Allowance', value: 'Allowance' },
  { display_name: 'As Is', value: 'As Is' },
  { display_name: 'Spec Suite', value: 'Spec Suite' },
  { display_name: 'Turn Key', value: 'Turn Key' },
];

const Expenses = ({
  modalFieldState,
  modalFieldChangeHandler,
  individualModalFieldChangeHandler,
  fieldErrorMap,
  validateLeaseAbstractionFields,
}) => (
  <FileUploadAccordian accordianTitle="Expenses">
    <LeaseAbstractionField
      key="tenantImprovementAllowance"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantImprovementAllowance,
        label: 'Tenant Improvement Allowance ($/SqFt)',
        errorMessage: fieldErrorMap.tenantImprovementAllowance,
      }}
      fieldChangeHandler={(event) => {
        const newValue = event.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(newValue) || newValue === '') {
          modalFieldChangeHandler('tenantImprovementAllowance', {
            value: newValue,
          });
        }
        // modalFieldChangeHandler('tenantImprovementAllowance', {
        //   value: event.target.value,
        // });
      }}
      individualFieldChangeHandler={(event) => {
        individualModalFieldChangeHandler({
          child_value: event.target.value,
          parent_key: 'comp_info',
          child_key: modalFieldState.tenantImprovementAllowance.trackingID,
          stateVariable: 'tenantImprovementAllowance',
        });
      }}
    />
    <LeaseAbstractionField
      key="parkingRatio"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.parkingRatio,
        label: 'Parking Ratio (spots per 1000 SqFt)',
        errorMessage: fieldErrorMap.parkingRatio,
      }}
      fieldChangeHandler={(event) => {
        const newValue = event.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(newValue) || newValue === '') {
          modalFieldChangeHandler('parkingRatio', { value: newValue });
        }
        // modalFieldChangeHandler('parkingRatio', {
        //   value: event.target.value,
        // });
      }}
      individualFieldChangeHandler={(event) => {
        individualModalFieldChangeHandler({
          child_value: event.target.value,
          parent_key: 'comp_info',
          child_key: modalFieldState.parkingRatio.trackingID,
          stateVariable: 'parkingRatio',
        });
      }}
      validateLeaseAbstractionFields={validateLeaseAbstractionFields}
    />
    <LeaseAbstractionField
      key="tenantImprovementDetails"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.tenantImprovementDetails,
        label: 'Tenant Improvement Details',
        errorMessage: fieldErrorMap.tenantImprovementDetails,
        options: tenantImprovementDetailOptions,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantImprovementDetails', {
          value: event.value,
        });
      }}
      individualFieldChangeHandler={(event) => {
        individualModalFieldChangeHandler({
          child_value: event.value,
          parent_key: 'comp_info',
          child_key: modalFieldState.tenantImprovementDetails.trackingID,
          stateVariable: 'tenantImprovementDetails',
        });
      }}
    />
  </FileUploadAccordian>
);

Expenses.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
  validateLeaseAbstractionFields: PropTypes.func,
};

Expenses.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
  validateLeaseAbstractionFields: () => {},
};

export default Expenses;
