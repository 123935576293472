import { useReducer } from 'react';
import countries from '../data/countries.json';
import states from '../data/states.json';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const modalFieldsReducer = (currentModalFields, action) => {
  let newValue;
  let updatedFields;
  if (action.type === 'multiple') {
    updatedFields = Object.keys(action.value).reduce((updatedValue, key) => {
      const fieldValue = action.value[key];
      return {
        ...updatedValue,
        [key]: {
          ...currentModalFields[key],
          ...fieldValue,
        },
      };
    }, {});
  }
  switch (action.type) {
    case 'single':
      newValue = {
        ...currentModalFields,
        [action.field]: {
          ...currentModalFields[action.field],
          ...action.value,
        },
      };
      break;

    case 'multiple':
      newValue = {
        ...currentModalFields,
        ...updatedFields,
      };
      break;

    default:
      newValue = {
        ...currentModalFields,
        [action.field]: {
          ...currentModalFields[action.field],
          value: action.value,
        },
      };
      break;
  }
  return newValue;
};

const useFileAbstractionFields = () => {
  const [modalFieldState, dispatchModalFieldState] = useReducer(
    modalFieldsReducer,
    {
      leaseStartDate: {
        value: '',
        trackingID: 'lease_start_date',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isLeaseStartDateValid: true,
      leaseEndDate: {
        value: '',
        trackingID: 'lease_expiration_date',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isLeaseEndDateValid: true,
      leaseExecutionDate: {
        value: '',
        trackingID: 'lease_execution_date',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isLeaseExecutionDateValid: true,
      leaseOccupancyDate: {
        value: '',
        trackingID: 'lease_occupancy_date',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isLeaseOccupancyDateValid: true,
      isASublease: {
        value: '',
        trackingID: 'is_sublease',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      propertyName: {
        value: '',
        trackingID: 'property_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      country: {
        value: 'United States',
        trackingID: 'country',
        toolTip: {
          section: 'This is a default value',
          paragraph: '',
          pageIndex: '',
        },
      },
      address: {
        value: '',
        trackingID: 'address',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      city: {
        value: '',
        trackingID: 'city',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      stateName: {
        value: '',
        trackingID: 'state_province',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      zip: {
        value: '',
        trackingID: 'zip',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isWholeBuilding: {
        value: '',
        trackingID: 'is_whole_building',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      propertyType: {
        value: '',
        trackingID: 'property_type_subtype',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      parcelSize: {
        value: '',
        trackingID: 'parcel_size',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      isListedByCBRE: {
        value: '',
        trackingID: 'is_listed_by_cbre',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      suite: {
        value: '',
        trackingID: 'suite',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      floorChange: {
        value: '',
        trackingID: 'floor_number',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      squareFeet: {
        value: '',
        trackingID: 'square_footage',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      filteredStatesForProperty: { value: getFilteredStates() },
      tenantCompanyName: {
        value: '',
        trackingID: 'org_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantCountryName: {
        value: 'United States',
        trackingID: 'country',
        toolTip: {
          section: 'This is a default value',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantAddressLine1: {
        value: '',
        trackingID: 'address',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantAddressLine2: {
        value: '',
        trackingID: 'address2',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantCity: {
        value: '',
        trackingID: 'city',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantStateName: {
        value: '',
        trackingID: 'state_province',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantZip: {
        value: '',
        trackingID: 'zip',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantPOCFirstName: {
        value: '',
        trackingID: 'contact_first_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantPOCLastName: {
        value: '',
        trackingID: 'contact_last_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantPOCEmail: {
        value: '',
        trackingID: 'contact_email',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantPOCPhoneValid: true,
      tenantPOCPhone: {
        value: '',
        trackingID: 'contact_phone',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantPOCJobTitle: {
        value: '',
        trackingID: 'contact_title',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      filteredStatesForTenant: { value: getFilteredStates() },
      landlordCompanyName: {
        value: '',
        trackingID: 'org_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordCountryName: {
        value: 'United States',
        trackingID: 'country',
        toolTip: {
          section: 'This is a default value',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordAddressLine1: {
        value: '',
        trackingID: 'address',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordAddressLine2: {
        value: '',
        trackingID: 'address2',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordCity: {
        value: '',
        trackingID: 'city',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordStateName: {
        value: '',
        trackingID: 'state_province',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordZip: {
        value: '',
        trackingID: 'zip',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordPOCFirstName: {
        value: '',
        trackingID: 'contact_first_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordPOCLastName: {
        value: '',
        trackingID: 'contact_last_name',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordPOCEmail: {
        value: '',
        trackingID: 'contact_email',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordPOCPhoneValid: true,
      landlordPOCPhone: {
        value: '',
        trackingID: 'contact_phone',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordPOCJobTitle: {
        value: '',
        trackingID: 'contact_title',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      filteredStatesForLandlord: { value: getFilteredStates() },
      tenantRepresentCompany: {
        value: '',
        trackingID: 'tenant_represented_by',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantRepresentAgent: {
        value: '',
        trackingID: 'tenant_represented_by_agent',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordRepresentCompany: {
        value: '',
        trackingID: 'landlord_represented_by',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      landlordRepresentAgent: {
        value: '',
        trackingID: 'landlord_represented_by_agent',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      baseRent: {
        value: '',
        trackingID: 'base_rent',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantImprovementAllowance: {
        value: '',
        trackingID: 'tenant_improvement_allowance_amount_per_sqft',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      parkingRatio: {
        value: '',
        trackingID: 'parking_ratio',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
      tenantImprovementDetails: {
        value: '',
        trackingID: 'tenant_improvements_detail',
        toolTip: {
          section: '',
          paragraph: '',
          pageIndex: '',
        },
      },
    },
  );
  return [modalFieldState, dispatchModalFieldState];
};

export default useFileAbstractionFields;
