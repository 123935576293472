import React from 'react';
import PropTypes from 'prop-types';

import LeaseInformation from './sections/lease-information';
import TenantInformation from './sections/tenant-information';
import LandlordInformation from './sections/landlord-information';

const LeaseDetails = ({
  modalFieldState,
  modalFieldChangeHandler,
  propertyOptions,
  fieldErrorMap,
  validateLeaseAbstractionFields,
  individualModalFieldChangeHandler,
}) => (
  <>
    <LeaseInformation
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      individualModalFieldChangeHandler={individualModalFieldChangeHandler}
      propertyOptions={propertyOptions}
      fieldErrorMap={fieldErrorMap}
      validateLeaseAbstractionFields={validateLeaseAbstractionFields}
    />
    <TenantInformation
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      individualModalFieldChangeHandler={individualModalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
    <LandlordInformation
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      individualModalFieldChangeHandler={individualModalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
  </>
);

LeaseDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  })),
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
  validateLeaseAbstractionFields: PropTypes.func,
};

LeaseDetails.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  propertyOptions: [
    { display_name: 'Select', value: null },
  ],
  fieldErrorMap: {},
  validateLeaseAbstractionFields: () => {},
};

export default LeaseDetails;
