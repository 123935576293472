import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

const RentAndEscalations = ({
  modalFieldState,
  modalFieldChangeHandler,
  individualModalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Rent & Escalation">
    <LeaseAbstractionField
      key="baseRent"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.baseRent,
        label: 'Base Rent($/SqFt per year)',
        errorMessage: fieldErrorMap.baseRent,
      }}
      fieldChangeHandler={(event) => {
        const newValue = event.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(newValue) || newValue === '') {
          modalFieldChangeHandler('baseRent', { value: newValue });
        }
        // modalFieldChangeHandler('baseRent', {
        //   value: event.target.value,
        // });
      }}
      individualFieldChangeHandler={(event) => {
        individualModalFieldChangeHandler({
          child_value: event.target.value,
          parent_key: 'comp_info',
          child_key: modalFieldState.baseRent.trackingID,
          stateVariable: 'baseRent',
        });
      }}
    />
  </FileUploadAccordian>
);

RentAndEscalations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  individualModalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

RentAndEscalations.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default RentAndEscalations;
